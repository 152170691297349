$social-button-size: 2.5rem;
$social-button-border-width: .125rem;
$social-button-font-size: 1.25rem;
$social-button-line-height: 1.8em;
$social-button-border-radius: 1.6875rem;
$social-button-transition: 0.5s ease all;
$social-button-margin: .25rem;

// Source: https://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-linkedin: #007bb5;
$social-brand-google-plus: #dd4b39;

@mixin social-button($brand-color, $brand-icon) {
  background: $brand-color;

  &:before {
    font-family: "FontAwesome";
    content: $brand-icon;
  }
  &:hover,
  &:focus {
    color: $brand-color;
    background: $white;
    border-color: $brand-color;
  }
}

.news-image-gallery-container p {
  // color: black;
  color: #707272;
}

// .news-image-gallery-container h4 {
//   color: #707272;
// }

.news-image-gallery-container {
  background-color: $white;
  padding: 2rem 1.5rem 1rem;

  .rounded-social-buttons {
    text-align: left;

    .social-button {
      display: inline-block;
      position: relative;
      cursor: pointer;
      width: $social-button-size;
      height: $social-button-size;
      border: $social-button-border-width solid transparent;
      padding: 0;
      text-decoration: none;
      text-align: center;
      color: $white;
      font-size: $social-button-font-size;
      font-weight: normal;
      line-height: $social-button-line-height;
      border-radius: $social-button-border-radius;
      transition: $social-button-transition;
      margin-right: $social-button-margin;
      margin-bottom: $social-button-margin;

      &:hover,
      &:focus {
        transform: rotate(360deg);
      }

      &.facebook {
        @include social-button($social-brand-facebook, "\f09a")
      }
      &.twitter {
        @include social-button($social-brand-twitter, "\f099")
      }
      &.linkedin {
        @include social-button($social-brand-linkedin, "\f0e1")
      }
      &.google-plus {
        @include social-button($social-brand-google-plus, "\f0d5")
      }
    }
  }

  .news-image-gallery-title {
    margin-top: .5rem;
  }

  .read-more {
    color: $dark-gray;
  }
}
