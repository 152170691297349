$subnav-hero-bg: #533058;
$subnav-hero-link-padding: 0.9rem 1rem;
$subnav-hero-font-size: 0.75rem;
$subnav-hero-link-opacity: 0.15;
$subnav-hero-height: 18.75em;
$subnav-hero-transition: all 0.35s ease-in-out;


#about p, #about ul li {
  color: #575757;
}
#eng-logo {
  padding-bottom: 3rem;
  padding-left: 2rem;
  width: 30%;
}

#title {
  width: 40%;
}

#contact {
  padding-right: 2rem;
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .subnav-hero-section {
    display: block!important;
    height: 35.75em!important;
  }
  .subnav-hero-section p {
    text-align: center!important;
  }

  #title, #contact, #eng-logo {
    width: auto;
  }
  #eng-logo {
    // margin-top: 1.5625em;
    padding: 30px 0 0 0;
  }
  #contact {
    padding-right: 0;
  }
  .columns {
    float: none;
  }
}

.subnav-hero-display-top {
  display: inline-flex;
}

.subnav-hero-section, .subnav-hero-headline, .subnav-hero-subnav li a, p, li {
  color: $white;
}

.subnav-hero-headline-p {
  font-size: 15px;
  font-weight: 350;
  text-transform: uppercase;
  text-align: center;
}

.subnav-hero-section p {
  text-align: right;
  text-transform: uppercase;
  font-size: 13px;
  margin: 0;
}

.subnav-hero-section {
  // text-align: center;
  background: $subnav-hero-bg;
  background-size: cover;
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $subnav-hero-height;

  .subnav-hero-headline {
    // color: #ceba7e;
    text-align: center;
  }

  @include breakpoint(small only) {
    .columns {
      margin-bottom: 2rem;
    }
  }

  @include breakpoint(medium down) {
    text-align: center;
  }
}

.subnav-hero-subnav {
  float: none;
  position: absolute;
  text-align: center;
  background: #77537c;
  margin: 0 auto;
  bottom: 0;
  width: 100%;

  li {
    float: none;
    display: inline-block;
    // color: #ceba7e;

    a {
      padding: $subnav-hero-link-padding;
      font-size: $subnav-hero-font-size;
      // color: #ceba7e;
      text-transform: uppercase;
      display: block;
      font-weight: bold;
      letter-spacing: 1px;
      transition: $subnav-hero-transition;

      &.is-active {
        background: rgba($white, $subnav-hero-link-opacity);
      }

      &:hover {
        background: rgba($white, $subnav-hero-link-opacity);
      }
    }
  }
}
